import Constants from './Constants';

const networkLayer = {
  getProductsGroupedByCategory: async () => {
    try {
      const response = await fetch(`${Constants.apiBaseUrl}/menu/getProductsGroupedByCategory`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const jsonResponse = await response.json();
      return jsonResponse;
    } catch (error) {
      console.error('Network error:', error);
      throw error;
    }
  },
  getProductsGroupedBySubCategory: async (categoryId) => {
    try {
      const response = await fetch(`${Constants.apiBaseUrl}/menu/getProductsGroupedBySubCategory?id=`+categoryId, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const jsonResponse = await response.json();
      return jsonResponse;
    } catch (error) {
      console.error('Network error:', error);
      throw error;
    }
  },

  getProductById: async (productId) => {
    try {
      const response = await fetch(`${Constants.apiBaseUrl}/menu/getProductById?productId=`+productId, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const jsonResponse = await response.json();
      return jsonResponse;
    } catch (error) {
      console.error('Network error:', error);
      throw error;
    }
  },

  getAllCategories: async () => {
    try {
      const response = await fetch(`${Constants.apiBaseUrl}/menu/getAllCategoriesForMenu`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const jsonResponse = await response.json();
      return jsonResponse;
    } catch (error) {
      console.error('Network error:', error);
      throw error;
    }
  },

  getImageBase64: async (imageId) => {
    try {
      const response = await fetch(`${Constants.apiBaseUrl}/menu/getImage?id=${imageId}`);
      if (response.ok) {
        const buffer = await response.arrayBuffer();
        return btoa(
          new Uint8Array(buffer)
            .reduce((data, byte) => data + String.fromCharCode(byte), '')
        );
      } else {
        return 'error';
      }
    } catch (error) {
      console.error('Error fetching image:', error);
      return 'error';
    }
  },

  getBannersByCategory: async (categoryId) => {
    try {
      const response = await fetch(`${Constants.apiBaseUrl}/menu/getBannersByCategory?categoryId=${categoryId}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const jsonResponse = await response.json();
      return jsonResponse;
    } catch (error) {
      console.error('Error fetching banners:', error);
      throw error;
    }
  },
};

export default networkLayer;
