
// ProductDetail.jsx

import React, { useEffect, useState } from 'react';
import './ProductDetail.css';
import CupIcon from '../../assets/img/cup.png';
import SmallIcon from '../../assets/img/small.png';
import LargeIcon from '../../assets/img/large.png';
import defaultImage from '../../assets/img/default.jpg';
import { useParams, useNavigate } from 'react-router-dom';
import { AppBar, Toolbar, IconButton, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import networkLayer from '../../utils/NetworkLayer';
import backgroundImage from '../../assets/splash_bg.png'; // Arka plan resmini içe aktarıyoruz
import './ProductDetail.css';

const renderSizeIcon = (size) => {
    switch (size) {
        case 'CUP':
            return <img src={CupIcon} alt="Cup Size" className="size-icon" />;
        case 'SMALL':
            return <img src={SmallIcon} alt="Small Size" className="size-icon" />;
        case 'LARGE':
            return <img src={LargeIcon} alt="Large Size" className="size-icon" />;
        default:
            return null;
    }
};

const ProductDetail = () => {
    const { id } = useParams();
    const [product, setProduct] = useState(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchProductDetail = async () => {
            try {
                const json = await networkLayer.getProductById(id);
                console.log(json)
                if (json.message === "Success") {
                    setProduct(json.data);
                } else {
                    console.error("Failed to fetch product details:", json.message);
                }
            } catch (error) {
                console.error("Failed to fetch product details:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchProductDetail();
    }, [id]);

    if (loading) {
        return <div className="loading-message">Yükleniyor...</div>;
    }

    if (!product) {
        return (
            <div className="product-detail" style={{ backgroundImage: `url(${backgroundImage})` }}>
                <AppBar position="fixed" style={{ backgroundColor: 'white', color: '#000428' }}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={() => navigate(-1)} aria-label="back">
                            <ArrowBackIcon />
                        </IconButton>
                        <Typography variant="h6" style={{ flexGrow: 1, textAlign: 'center' }}>
                            Ürün bulunamadı
                        </Typography>
                    </Toolbar>
                </AppBar>
                <div className="product-detail-content">
                    <h2 className="error-message">Ürün bulunamadı<br></br>Ana sayfaya yönlendiriliyorsunuz...</h2>
                    <p className="error-message">Ana sayfaya yönlendiriliyorsunuz...</p>
                </div>
            </div>
        );
    }

    return (
        <div className="product-detail">
            <AppBar position="fixed" style={{ backgroundColor: 'white', color: '#000428', zIndex: 10 }}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={() => navigate(-1)} aria-label="back">
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography variant="h6" style={{ flexGrow: 1, textAlign: 'center' }}>
                        {"Aura"}
                    </Typography>
                </Toolbar>
            </AppBar>
    
            {/* Wrapping the image and product info */}
            <div className="product-content">
                <img src={product.imagePath || defaultImage} alt={product.name} className="product-image" />
    
                <div className="product-info">
                    <h1 className="product-title">{product.name}</h1>
                    {product.price ? (
                        <div className="product-price">{product.price} ₺</div>
                    ) : (
                        <div className="product-sizes">
                        {product.productSizeableList.map(sizeOption => (
                            <div className="product-size" key={sizeOption.size}>
                                {renderSizeIcon(sizeOption.size)}
                                <span className="size-price">{sizeOption.price} ₺</span>
                            </div>
                        ))}
                    </div>
                    
                    )}
                </div>
    
                {/* Description area */}
                <div className="product-description">
                    <p>{product.description || "Açıklama yok."}</p>
                </div>
            </div>
        </div>
    );
    
    
};

export default ProductDetail;
