import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppBar, Toolbar, Typography } from '@mui/material';
import './CategoryListPage.css'; // Bu CSS dosyası her iki bileşeni de etkiler
import networkLayer from '../../utils/NetworkLayer'; // Bu yolun geçerli olduğundan emin olun

const CategoryListPage = () => {
  const [categoriesWithImages, setCategoriesWithImages] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state
  const navigate = useNavigate();

  // Kategorileri yüklemek için bir useEffect oluşturuyoruz
  useEffect(() => {
    const fetchCategories = async () => {
      setLoading(true); // Set loading to true when fetching starts
      setError(null); // Reset error state
      try {
        const categoryList = await networkLayer.getAllCategories(); // Kategorileri al
        const updatedCategories = await Promise.all(
          categoryList.data.map(async (category) => {
            try {
              const base64 = await networkLayer.getImageBase64(category.imageId);
              return { ...category, base64 };
            } catch (error) {
              console.error(`Error fetching image for category ${category.id}:`, error);
              return { ...category, base64: '' }; // Eğer resim yüklenemezse boş string ekleyelim
            }
          })
        );
        setCategoriesWithImages(updatedCategories);
      } catch (error) {
        console.error('Error fetching categories:', error);
        setError('Şuanda size yardımcı olamıyoruz :('); // Set error message
      } finally {
        setLoading(false); // Set loading to false when fetching is complete
      }
    };

    fetchCategories(); // Kategorileri çek
  }, []); // Boş bağımlılık dizisi ile sayfa ilk açıldığında çalışır

  const handleCategoryPress = (category) => {
    navigate(`/category/${category.id}`);
  };

  return (
    <div className="category-list-container">
      <AppBar 
        position="fixed" 
        style={{ 
          top: 0, 
          left: 0, 
          width: '100%', 
          backgroundColor: 'white', 
          borderBottom: '1px solid black', 
          color: 'black', // Simge ve yazı rengini siyah yapar
        }}
      >
        <Toolbar>
          <Typography variant="h6" style={{ flexGrow: 1, textAlign: 'center', color: '#000428' }}>
            AURA
          </Typography>
        </Toolbar>
      </AppBar>

      {loading ? (
        <p>Yükleniyor...</p> // Loading message
      ) : error ? (
        <p>{error}</p> // Error message
      ) : (
        categoriesWithImages.map((category) => (
          <div
            key={category.id}
            className="category-list-card"
            role="button"
            tabIndex={0}
            onClick={() => handleCategoryPress(category)}
            onKeyPress={(e) => e.key === 'Enter' && handleCategoryPress(category)}
          >
            <img
              src={`data:image/jpeg;base64,${category.base64}`}
              alt={category.name}
              className="image"
            />
            <h3 className="categoryName">{category.name}</h3>
          </div>
        ))
      )}
    </div>
  );
};

export default CategoryListPage;
