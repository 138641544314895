// CategoryDetail.jsx

import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { AppBar, Toolbar, IconButton, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import networkLayer from '../../utils/NetworkLayer';
import './CategoryDetail.css';
import CupIcon from '../../assets/img/cup_white.png';
import SmallIcon from '../../assets/img/small_white.png';
import LargeIcon from '../../assets/img/large_white.png';
import defaultImage from '../../assets/img/default.jpg';

const renderSizeIcon = (size) => {
    switch (size) {
        case 'CUP':
            return <img src={CupIcon} alt="Cup Size" className="size-icon" />;
        case 'SMALL':
            return <img src={SmallIcon} alt="Small Size" className="size-icon" />;
        case 'LARGE':
            return <img src={LargeIcon} alt="Large Size" className="size-icon" />;
        default:
            return null;
    }
};

const CategoryDetail = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [category, setCategory] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchCategoryDetail = async () => {
            try {
                const json = await networkLayer.getProductsGroupedBySubCategory(id);
                if (json.message === "Success") {
                    setCategory(json.data);
                } else {
                    console.error("Failed to fetch category details:", json.message);
                }
            } catch (error) {
                console.error("Failed to fetch category details:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchCategoryDetail();
    }, [id]);

    if (loading) {
        return <div className="loading-message">Yükleniyor...</div>;
    }

    if (!category) {
        return <div className="error-message">Şuanda size yardımcı olamıyorum :(</div>;
    }

    return (
        <div className="category-detail">
            <AppBar position="fixed" style={{ backgroundColor: 'white', color: '#000428' }}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={() => navigate(-1)} aria-label="back">
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography variant="h6" style={{ flexGrow: 1, textAlign: 'center' }}>
                        AURA
                    </Typography>
                </Toolbar>
            </AppBar>
            <div className="product-container">
                {category.subCategoryList.map(subCategory => {
                    const availableProducts = subCategory.lightProductDTOList.filter(product => product.isAvailable);
                    if (availableProducts.length === 0) return null;

                    return (
                        <div key={subCategory.id} className="sub-category-section">
                            <h2 className="sub-category-title">{subCategory.name}</h2>
                            <div className="product-grid">
                                {availableProducts.map(product => (
                                    <Link to={`/product/${product.id}`} key={product.id} className="product-card">
                                    <img src={product.imagePath || defaultImage} alt={product.name} className="product-image" />
                                    <div className="black-overlay"></div>
                                    <div className="product-title">{product.name}</div>
                                    {product.productSizeableList.length > 0 ? (
    <div className="product-sizes">
        {product.productSizeableList.length === 3
            ? product.productSizeableList
                  .filter(sizeOption => sizeOption.size === 'CUP' || sizeOption.size === 'LARGE')
                  .map(sizeOption => (
                      <div className="product-size" key={sizeOption.size}>
                          {renderSizeIcon(sizeOption.size)}
                          <span className="size-price">{sizeOption.price} ₺</span>
                      </div>
                  ))
            : product.productSizeableList.map(sizeOption => (
                <div className="product-size" key={sizeOption.size}>
                    {renderSizeIcon(sizeOption.size)}
                    <span className="size-price">{sizeOption.price} ₺</span>
                </div>
            ))}
    </div>
) : (
    <div className="product-price">{product.price} ₺</div>
)}

                                </Link>
                                
                                ))}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default CategoryDetail;
